import {
  faCircleCheck,
  faCircleXmark,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import { isZephyrEnv } from "~/constants";

function getErrorResponseText(error) {
  if (!error.response) {
    return error.message;
  }
  const { status, statusText } = error.response;
  const data = error.response.data ? JSON.stringify(error.response.data) : "";
  return `${status} ${statusText} ${data}`;
}

function StatusIcon({ status, error }) {
  const errorTooltip = (
    <Tooltip>
      <div>
        <p>Error: {error}</p>
      </div>
    </Tooltip>
  );
  return (
    <>
      {status === "uploading" && (
        <FontAwesomeIcon
          className="fa fa-spin fa-2x"
          icon={faSpinner}
          color="#0d6efd"
        />
      )}
      {status === "success" && (
        <FontAwesomeIcon
          className="fa fa-check fa-2x"
          icon={faCircleCheck}
          color="green"
        />
      )}
      {status === "error" && (
        <OverlayTrigger
          delay={{ show: 400, hide: 400 }}
          overlay={errorTooltip}
          transition={false}
        >
          <FontAwesomeIcon
            className="fa fa-times fa-2x"
            icon={faCircleXmark}
            color="red"
          />
        </OverlayTrigger>
      )}
    </>
  );
}

function findOptionByLabel(options, label) {
  return options.find(
    (option) => option.label.toLowerCase() === label.toLowerCase()
  );
}

function getDefaultOptionFromFilename(filename, options) {
  const containsOption = (name, option) => {
    // `option` substring should not be surrounded by non-alphanumeric characters.
    const regex = new RegExp(`(^|[^a-zA-Z0-9])${option}([^a-zA-Z0-9]|$)`);
    return regex.test(name);
  };
  return options.find((option) =>
    containsOption(filename.toLowerCase(), option.label.toLowerCase())
  );
}

function getDefaultCTA(filename, options, network) {
  const defaultCTA = getDefaultOptionFromFilename(filename, options);
  if (defaultCTA) {
    return defaultCTA;
  }
  if (!network) {
    return null;
  }
  return network.label.toLowerCase() !== "unity"
    ? findOptionByLabel(options, "CTA")
    : null;
}

function getDefaultLanguage(filename, options) {
  const defaultLanguage = isZephyrEnv() ? "UN" : "EN";
  return (
    getDefaultOptionFromFilename(filename, options) ||
    findOptionByLabel(options, defaultLanguage) ||
    null
  );
}

function parseZephyrNumberClicks(filename) {
  // match either a number or "FIN" after "C-", ensuring valid prefixes
  const match = filename.match(
    /(?:^|[^a-zA-Z0-9])C-((?:\d+|FIN))(?![a-zA-Z0-9])/i
  );
  if (match) {
    // Check if the captured value is a number
    const value = match[1];
    return /^\d+$/.test(value) ? parseInt(value, 10) : value.toLowerCase();
  }
  return null;
}

function getDefaultChange(filename) {
  // matches a separator followed by 'ch-' and the part after it
  const regex = /(?:^|[|_-])ch-([^|_-]+)/;
  const match = filename.match(regex);
  return match ? match[1] : null;
}

function ReasonTooltip({ reason, children }) {
  if (reason) {
    return (
      <OverlayTrigger overlay={<Tooltip>{reason}</Tooltip>}>
        <span style={{ cursor: "pointer" }} className="d-inline-block">
          {children}
        </span>
      </OverlayTrigger>
    );
  }
  return <span className="d-inline-block">{children}</span>;
}

function generateNumberOfClicksOptions(num, defaultOptions = null) {
  const clicksOptions = defaultOptions || [];
  for (let i = 1; i <= num; i++) {
    clicksOptions.push({ label: i.toString(), value: i });
  }
  return clicksOptions;
}

function generateVersionsOptions(minVersion, maxVersion) {
  const versions = [];
  for (let i = minVersion; i <= maxVersion; i++) {
    const version = `V${i}`;
    versions.push({ value: version, label: version });
  }
  return versions;
}

export {
  getErrorResponseText,
  StatusIcon,
  getDefaultOptionFromFilename,
  getDefaultCTA,
  getDefaultLanguage,
  getDefaultChange,
  ReasonTooltip,
  generateNumberOfClicksOptions,
  generateVersionsOptions,
  parseZephyrNumberClicks,
};
