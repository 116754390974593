import moment from "moment";
import React from "react";
import { Card, Col, Row, Table } from "react-bootstrap";
import { useLoaderData, defer, useRevalidator } from "react-router-dom";

import { filtersLoader } from "~/blocks/creatives/assets/filters";
import {
  DeleteFilesButton,
  SelectAllFilesCheckbox,
} from "~/blocks/delete-files";
import { PreviewableText } from "~/blocks/file-preview/file-preview";
import { GdriveLink, ExternalLink } from "~/components/external-link";
import PageHeader from "~/components/page-header";
import Pagination from "~/components/pagination";
import { axios, extractSearch, addDefaultOrderingToSearch } from "~/utils";

import { AssetDropzone, GdriveCredsDropzoneGuard } from "./dropzone";
import { UploadAssetsModal } from "./upload-modal";

import "./assets.scss";

function EndCardAssetsPage() {
  return <EndCardAssetsFunctionPage />;
}

function EndCardAssetsFunctionPage() {
  const { endCardAssets, endCard, languages, resolutions, networks } =
    useLoaderData();
  const revalidator = useRevalidator();

  const refreshPage = () => {
    revalidator.revalidate();
  };

  return (
    <>
      <PageHeader />
      <Row className="mb-4 pt-4">
        <Col xs={6}>
          <EndCardDescriptionBlock endCard={endCard} />
        </Col>
        <Col xs={6}>
          <GdriveCredsDropzoneGuard>
            <AssetDropzone>
              {({ filesToUpload, removeFile, clearFiles }) =>
                !!filesToUpload.length && (
                  <UploadAssetsModal
                    filesToUpload={filesToUpload}
                    onFileRemove={removeFile}
                    onClose={(shouldRefreshPage) => {
                      clearFiles();
                      if (shouldRefreshPage) {
                        refreshPage();
                      }
                    }}
                    endCard={endCard}
                    languages={languages}
                    resolutions={resolutions}
                    networks={networks}
                  />
                )
              }
            </AssetDropzone>
          </GdriveCredsDropzoneGuard>
        </Col>
      </Row>
      <EndCardAssetsTable data={endCardAssets} refreshPage={refreshPage} />
    </>
  );
}

EndCardAssetsPage.loader = async function loader({ params, request }) {
  const search = extractSearch(request.url);
  const defaultOrderingTemplate =
    "ordering=normalized_change,language_field,network_field";
  const updatedSearch = addDefaultOrderingToSearch(
    search,
    defaultOrderingTemplate
  );
  const [assetsResponse] = await Promise.all([
    axios(`end-cards/concept/${params.conceptID}${updatedSearch}`),
  ]);
  const [endCardResponse] = await Promise.all([
    axios(`end-card-concepts/${params.conceptID}`),
  ]);
  const [languagesResponse] = await Promise.all([axios("languages/")]);
  const [resolutionsResponse] = await Promise.all([
    axios("end-card-resolutions/"),
  ]);
  const [networksResponse] = await Promise.all([axios("networks/")]);
  const filtersPromise = filtersLoader();

  return defer({
    endCardAssets: assetsResponse.data,
    endCard: endCardResponse.data,
    languages: languagesResponse.data,
    resolutions: resolutionsResponse.data,
    networks: networksResponse.data,
    filtersPromise,
  });
};

function EndCardAssetsTable({ data, refreshPage }) {
  const [selectedEndCards, setSelectedEndCards] = React.useState([]);
  const toggleRowSelection = (id) => {
    if (selectedEndCards.includes(id)) {
      setSelectedEndCards(selectedEndCards.filter((rowId) => rowId !== id));
    } else {
      setSelectedEndCards([...selectedEndCards, id]);
    }
  };

  return (
    <>
      <DeleteFilesButton
        endpoint="end-cards"
        files={selectedEndCards}
        refreshPage={refreshPage}
      />
      <Table striped bordered hover>
        <thead>
          <tr>
            <th scope="col">
              <SelectAllFilesCheckbox
                selectedFiles={selectedEndCards}
                setSelectedFiles={setSelectedEndCards}
                allFiles={data.results}
              />
            </th>
            <th scope="col" style={{ width: "4em" }}>
              ID
            </th>
            <th scope="col">Long Name</th>
            <th scope="col">CTA</th>
            <th scope="col">Resolution</th>
            <th scope="col">Change</th>
            <th scope="col">Language</th>
            <th scope="col">Network</th>
            <th scope="col">Google Drive Link</th>
          </tr>
        </thead>
        <tbody>
          {data.results.map((endCardAsset) => (
            <tr
              key={endCardAsset.id}
              style={{ height: "4em", verticalAlign: "middle" }}
            >
              <td>
                <input
                  type="checkbox"
                  checked={selectedEndCards.includes(endCardAsset.id)}
                  onChange={() => toggleRowSelection(endCardAsset.id)}
                />
              </td>
              <td>{endCardAsset.id}</td>
              <td>
                <PreviewableText
                  preview={endCardAsset.preview}
                  text={endCardAsset.long_name}
                />
              </td>
              <td>{endCardAsset.cta_field}</td>
              <td>{endCardAsset.resolution}</td>
              <td>{endCardAsset.change}</td>
              <td>{endCardAsset.language_field}</td>
              <td>{endCardAsset.network_field}</td>
              <td>
                <GdriveLink link={endCardAsset.creative_link} />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination data={data} />
    </>
  );
}

function EndCardDescriptionBlock({ endCard }) {
  return (
    <Card>
      <Card.Body>
        <Row className="mb-3">
          <Row>
            <Col style={{ fontWeight: "bold" }}>Date</Col>
            <Col style={{ fontWeight: "bold" }}>App</Col>
            <Col style={{ fontWeight: "bold" }}>End Card #</Col>
            <Col style={{ fontWeight: "bold" }}>End Card Theme</Col>
          </Row>
          <Row>
            <Col>
              {moment.utc(endCard.created_at).format("DD.MM.YYYY, HH:mm:ss")}
            </Col>
            <Col>{endCard.application_code}</Col>
            <Col>{endCard.creative_number}</Col>
            <Col>{endCard.theme}</Col>
          </Row>
        </Row>
        <Row>
          <Row>
            <Col style={{ fontWeight: "bold" }}>User</Col>
            <Col style={{ fontWeight: "bold" }}>UA Folder</Col>
            <Col style={{ fontWeight: "bold" }}>Creative Project Folder</Col>
            <Col style={{ fontWeight: "bold" }}>Asana ticket</Col>
          </Row>
          <Row>
            <Col>{endCard.created_by_name}</Col>
            <Col>
              <GdriveLink link={endCard.creative_folder_link} />
            </Col>
            <Col>
              <GdriveLink link={endCard.creative_team_folder_link} />
            </Col>
            <Col>
              <ExternalLink link={endCard.asana_link} text="Link" />
            </Col>
          </Row>
        </Row>
      </Card.Body>
    </Card>
  );
}

export default EndCardAssetsPage;
