import { useFormikContext } from "formik";
import { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";

import { useAlert } from "~/base/alerts";
import Form from "~/components/form";
import { axios } from "~/utils";

function sortAssets(array, creative) {
  // sort assets by creative number, moving assets with given creative to the head of list
  const sortedArray = array.sort((a, b) => b.creative - a.creative);
  const creativeElements = sortedArray.filter(
    (item) => item.creative === creative
  );
  const otherElements = sortedArray.filter(
    (item) => item.creative !== creative
  );
  return creativeElements.concat(otherElements);
}

function assetToOptions(assets) {
  return assets.map((asset) => ({
    label: asset.long_name,
    value: asset.id,
  }));
}

function AssetInput({ assets, name, disabled, label, children }) {
  if (!assets.includes(name)) {
    return "";
  }
  const classes = disabled ? "mb-3 pb-3 disabled" : "mb-3 pb-3";
  return (
    <LaunchFormRow label={label} className={classes}>
      {children}
    </LaunchFormRow>
  );
}

const CreativePackType = Object.freeze({
  UNITY_VIDEO_ENDCARD: "unity-video-endcard",
  APPLOVIN_VIDEO_ENDCARD: "applovin-video-endcard",
  UNITY_VIDEO_PLAYABLE: "unity-video-playable",
  APPLOVIN_VIDEO_PLAYABLE: "applovin-video-playable",
  PLAYABLE: "playable",
});

const packTypeAssetsMap = {
  [CreativePackType.UNITY_VIDEO_ENDCARD]: [
    "portraitVideoAsset",
    "landscapeVideoAsset",
    "endCard",
    "endCard2",
  ],
  [CreativePackType.APPLOVIN_VIDEO_ENDCARD]: ["portraitVideoAsset", "endCard"],
  [CreativePackType.UNITY_VIDEO_PLAYABLE]: [
    "portraitVideoAsset",
    "landscapeVideoAsset",
    "playable",
  ],
  [CreativePackType.APPLOVIN_VIDEO_PLAYABLE]: [
    "portraitVideoAsset",
    "playable",
  ],
  [CreativePackType.PLAYABLE]: ["playable"],
};

function AssetInputs({ isZephyr, optionsEndpoint }) {
  const {
    values: {
      application,
      portraitVideoAsset,
      endCard,
      endCard2,
      landscapeVideoAsset,
      creativePackType,
    },
  } = useFormikContext();
  const addAlert = useAlert();
  const [portraitVideos, setPortraitVideos] = useState([]);
  const [landscapeVideos, setLandscapeVideos] = useState([]);
  const [endCardOptions, setEndCardOptions] = useState([]);
  const [playableOptions, setPlayableOptions] = useState([]);

  useEffect(() => {
    if (application !== null) {
      // for zephyr we don't want to filter assets by application
      const applicationFilter = isZephyr ? "" : `?application=${application}`;
      axios({
        method: "GET",
        url: `${optionsEndpoint}/${applicationFilter}`,
      })
        .then((response) => {
          setPortraitVideos(response.data.portrait_videos);
          setLandscapeVideos(response.data.landscape_videos);
          setEndCardOptions(response.data.end_cards);
          setPlayableOptions(response.data.playables);
        })
        .catch(() => {
          addAlert(
            "Error on loading creative concepts and end cards. Please try again or reach out to the MAD Products Team",
            "error"
          );
        });
    }
  }, [application, addAlert, isZephyr, optionsEndpoint]);
  useEffect(() => {
    if (portraitVideoAsset !== null) {
      const selectedPortraitVideo = portraitVideos.find(
        (video) => video.id === portraitVideoAsset
      );
      setLandscapeVideos((videos) =>
        sortAssets(videos, selectedPortraitVideo.creative)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portraitVideoAsset]);
  useEffect(() => {
    if (landscapeVideoAsset) {
      const selectedLandscapeVideoAsset = landscapeVideos.find(
        (video) => video.id === landscapeVideoAsset
      );
      setPortraitVideos((videos) =>
        sortAssets(videos, selectedLandscapeVideoAsset.creative)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [landscapeVideoAsset]);
  useEffect(() => {
    if (endCard !== null) {
      const selectedEndCard = endCardOptions.find((e) => e.id === endCard);
      setEndCardOptions((endCards) =>
        sortAssets(endCards, selectedEndCard.creative)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endCard]);
  useEffect(() => {
    if (endCard2 !== null) {
      const selectedEndCard = endCardOptions.find((e) => e.id === endCard2);
      setEndCardOptions((endCards) =>
        sortAssets(endCards, selectedEndCard.creative)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endCard2]);

  if (creativePackType === null || application === null) {
    return "";
  }
  const assets = packTypeAssetsMap[creativePackType];

  function getEndCardType(endCardId) {
    const card = endCardOptions.find((e) => e.id === endCardId);
    return card ? card.type : null;
  }

  const squareEndCardSelected = getEndCardType(endCard) === "square";
  return (
    <div>
      <AssetInput
        assets={assets}
        name="portraitVideoAsset"
        label="Portrait Video:*"
      >
        <Form.Select
          name="portraitVideoAsset"
          options={assetToOptions(portraitVideos)}
          isClearable
        />
        <Form.Control.ErrorFeedback name="portraitVideoAsset" />
      </AssetInput>
      <AssetInput
        assets={assets}
        name="landscapeVideoAsset"
        label="Landscape Video:*"
      >
        <Form.Select
          name="landscapeVideoAsset"
          options={assetToOptions(landscapeVideos)}
          isClearable
        />
        <Form.Control.ErrorFeedback name="landscapeVideoAsset" />
      </AssetInput>
      <AssetInput assets={assets} name="endCard" label="End Card #:*">
        <Form.Select name="endCard" options={assetToOptions(endCardOptions)} />
        <Form.Control.ErrorFeedback name="endCard" />
      </AssetInput>
      <AssetInput
        disabled={squareEndCardSelected}
        assets={assets}
        name="endCard2"
        label="End Card 2 #:"
      >
        <Form.Select
          name="endCard2"
          options={assetToOptions(endCardOptions)}
          isClearable
        />
        <Form.Control.ErrorFeedback name="endCard2" />
      </AssetInput>
      <AssetInput assets={assets} name="playable" label="Playable #:*">
        <Form.Select name="playable" options={playableOptions} isClearable />
        <Form.Control.ErrorFeedback name="playable" />
      </AssetInput>
    </div>
  );
}

function LaunchFormRow({ label, children, className = "mb-3 pb-3" }) {
  return (
    <Row className={className}>
      <Col xs={2}>{label}:</Col>
      <Col xs={8}>{children}</Col>
    </Row>
  );
}

export { CreativePackType, AssetInputs, LaunchFormRow };
