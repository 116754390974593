import axios from "axios";
import React, { useEffect, useReducer, useState } from "react";
import { Button, Col, Modal, Row, Form } from "react-bootstrap";

import {
  getErrorResponseText,
  StatusIcon,
  getDefaultOptionFromFilename,
  getDefaultCTA,
  getDefaultLanguage,
  ReasonTooltip,
} from "~/blocks/upload-utils";
import { DetachedSelect } from "~/components/form/select";
import { axios as internalAxios } from "~/utils";

const baseAxiosClient = axios.create({});

function UploadAssetsModal({
  filesToUpload,
  onFileRemove,
  endCard,
  onClose,
  languages,
  resolutions,
  networks,
}) {
  const [extraState, dispatch] = useReducer(
    extraStateReducer,
    filesToUpload,
    getInitialExtraState
  );
  const [isRetryInProgress, setIsRetryInProgress] = useState(false);

  const uploadAsset = (file) => {
    dispatch({ type: "set-status", key: file.key, status: "uploading" });
    const state = extraState.find((e) => e.key === file.key);
    const formData = new FormData();
    formData.append("file_type", file.file.type);
    formData.append("original_file_name", file.file.name);
    return internalAxios({
      method: "POST",
      url: "assets/start-upload/",
      data: formData,
      timeout: 5 * 60 * 1000,
    })
      .then((startUploadResponse) => {
        const s3Data = startUploadResponse.data.presigned_data;
        const postData = new FormData();
        postData.append("key", s3Data.fields.key);
        postData.append("AWSAccessKeyId", s3Data.fields.AWSAccessKeyId);
        postData.append("policy", s3Data.fields.policy);
        postData.append("acl", s3Data.fields.acl);
        postData.append("signature", s3Data.fields.signature);
        postData.append("Content-Type", s3Data.fields["Content-Type"]);
        postData.append("file", file.file);
        baseAxiosClient({
          method: "POST",
          url: s3Data.url,
          data: postData,
          timeout: 5 * 60 * 1000,
        })
          .then(() => {
            const finishPostData = {
              original_file_name: file.file.name,
              file_type: file.file.type,
              file_size: file.file.size,
              language: state.language,
              duration: state.duration,
              resolution: state.resolution,
              network: state.network,
              cta: state.cta,
              change: state.change,
              file_key: s3Data.fields.key,
            };
            internalAxios({
              method: "POST",
              url: `end-card-concepts/${endCard.id}/finish_upload/`,
              data: finishPostData,
              timeout: 5 * 60 * 1000,
            })
              .then(() => {
                dispatch({
                  type: "set-status",
                  key: file.key,
                  status: "success",
                });
              })
              .catch((error) => {
                dispatch({
                  type: "set-status",
                  key: file.key,
                  status: "error",
                  error: getErrorResponseText(error),
                });
              });
          })
          .catch((error) => {
            processUploadError(error, endCard.id);
            dispatch({
              type: "set-status",
              key: file.key,
              status: "error",
              error: getErrorResponseText(error),
            });
          });
      })
      .catch((error) => {
        dispatch({
          type: "set-status",
          key: file.key,
          status: "error",
          error: getErrorResponseText(error),
        });
      });
  };
  const uploadAssets = () => {
    Promise.all(filesToUpload.map(uploadAsset));
  };
  const setLanguage = (key, language) => {
    dispatch({ type: "set-language", key, language });
  };
  const setResolution = (key, resolution) => {
    dispatch({ type: "set-resolution", key, resolution });
  };
  const setNetwork = (key, network) => {
    dispatch({ type: "set-network", key, network });
  };
  const setCTA = (key, cta) => {
    dispatch({ type: "set-cta", key, cta });
  };
  const setChange = (key, change) => {
    dispatch({ type: "set-change", key, change });
  };
  const removeFile = (key) => {
    onFileRemove(key);
    dispatch({ type: "remove-entry", key });
  };
  const retryFailedUploads = () => {
    const failedFileKeys = extraState
      .filter((e) => e.status === "error")
      .map((e) => e.key);
    const failedFiles = filesToUpload.filter((e) =>
      failedFileKeys.includes(e.key)
    );
    setIsRetryInProgress(true);
    Promise.allSettled(failedFiles.map(uploadAsset)).finally(() => {
      setIsRetryInProgress(false);
    });
  };

  const [isDisabled, submitDisabledReason] = isSubmitDisabled(extraState);
  const isInitialState = extraState.every((e) => e.status === "prepare");
  const isUploadingState = extraState.some((e) => e.status === "uploading");
  const isTerminalState = extraState.every(
    (e) => e.status === "success" || e.status === "error"
  );
  const hasFailedUploads = extraState.some((e) => e.status === "error");
  const closeModal = () => {
    const shouldRefreshPage = !isInitialState;
    onClose(shouldRefreshPage);
  };

  return (
    <Modal backdrop="static" onHide={closeModal} show centered>
      <Modal.Header closeButton>
        <Modal.Title>Upload new assets</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isUploadingState && (
          <div className="mb-2 ms-3">
            <p className="h6" style={{ color: "red" }}>
              Creatives are being uploaded to the Cactus server.
              <br />
              Please do not close the page until the uploads to Cactus are
              completed.
            </p>
          </div>
        )}
        {isTerminalState && (
          <div className="mb-2 ms-3">
            <p className="h6">
              Uploads to Cactus are completed.
              {hasFailedUploads && "You can retry the failed uploads."}
              <br />
            </p>
          </div>
        )}
        <div className="endcard-upload-modal mx-2">
          <Row className="mb-3">
            <Col className="header width-x4">File:</Col>
            <Col className="header width-x2">Change:</Col>
            <Col className="header width-x1p5">Network:</Col>
            <Col className="header width-x1p5">CTA:</Col>
            <Col className="header width-x1p5">Resolution:</Col>
            <Col className="header">Language:</Col>
            <Col className="header" />
            {isInitialState && <Col className="header" />}
          </Row>
          {filesToUpload.map((fileAdded) => (
            <UploadAssetBlock
              key={fileAdded.key}
              fileAdded={fileAdded}
              languages={languages}
              resolutions={resolutions}
              networks={networks}
              removeFile={removeFile}
              state={extraState.find((e) => e.key === fileAdded.key)}
              setLanguage={setLanguage}
              setResolution={setResolution}
              setNetwork={setNetwork}
              setCTA={setCTA}
              setChange={setChange}
              showRemoveColumn={isInitialState}
            />
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        {!isTerminalState && (
          <ReasonTooltip reason={submitDisabledReason}>
            <Button
              variant="primary"
              onClick={uploadAssets}
              disabled={isDisabled}
            >
              Upload
            </Button>
          </ReasonTooltip>
        )}
        {isTerminalState && hasFailedUploads && (
          <Button
            variant="primary"
            onClick={retryFailedUploads}
            disabled={isRetryInProgress}
          >
            Retry all failed uploads
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

function UploadAssetBlock({
  fileAdded,
  languages,
  resolutions,
  networks,
  removeFile,
  state,
  setLanguage,
  setResolution,
  setNetwork,
  setCTA,
  setChange,
  showRemoveColumn,
}) {
  const ctaOptions = [
    { value: true, label: "CTA" },
    { value: false, label: "NCTA" },
  ];
  const filename = fileAdded.file.name;
  const defaultLanguage = getDefaultLanguage(filename, languages);
  const defaultNetwork = getDefaultOptionFromFilename(filename, networks);
  const defaultCTA = getDefaultCTA(filename, ctaOptions, defaultNetwork);
  const defaultResolution = getDefaultOptionFromFilename(filename, resolutions);
  useEffect(() => {
    setLanguage(state.key, defaultLanguage ? defaultLanguage.value : null);
    setNetwork(state.key, defaultNetwork ? defaultNetwork.value : null);
    setCTA(state.key, defaultCTA ? defaultCTA.value : null);
    setResolution(
      state.key,
      defaultResolution ? defaultResolution.value : null
    );
    setChange(state.key, null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Row className="mb-3">
      <Col className="width-x4 break-word">{fileAdded.file.name}</Col>
      <Col className="width-x2">
        <Form.Control
          defaultValue={null}
          type="text"
          onChange={(e) => setChange(state.key, e.target.value)}
        />
      </Col>
      <Col className="width-x1p5">
        <DetachedSelect
          defaultValue={defaultNetwork}
          options={networks}
          onChange={(value) => setNetwork(state.key, value)}
          isError={state.network === null}
        />
      </Col>
      <Col className="width-x1p5">
        <DetachedSelect
          defaultValue={defaultCTA}
          options={ctaOptions}
          onChange={(value) => setCTA(state.key, value)}
          isError={state.cta === null}
        />
      </Col>
      <Col className="width-x1p5">
        <DetachedSelect
          defaultValue={defaultResolution}
          options={resolutions}
          onChange={(value) => setResolution(state.key, value)}
          isError={state.resolution === null}
        />
      </Col>
      <Col>
        <DetachedSelect
          defaultValue={defaultLanguage}
          options={languages}
          onChange={(value) => setLanguage(state.key, value)}
          isError={state.language === null}
        />
      </Col>
      {showRemoveColumn && (
        <Col>
          <Button
            onClick={() => removeFile(fileAdded.key)}
            type="button"
            variant="outline-primary"
          >
            Remove
          </Button>
        </Col>
      )}
      <Col>
        <StatusIcon status={state.status} error={state.error} />
      </Col>
    </Row>
  );
}

function isSubmitDisabled(extraState) {
  const notNull = (val) => val !== null;
  if (extraState.some((e) => e.status === "uploading")) {
    return [true, "Upload is in progress."];
  }
  if (
    !extraState.every(
      (e) =>
        notNull(e.language) &&
        notNull(e.resolution) &&
        notNull(e.network) &&
        notNull(e.cta)
    )
  ) {
    return [true, "All required asset fields should be set before uploading."];
  }
  return [false, null];
}

function getInitialExtraState(filesToUpload) {
  return filesToUpload.map((file) => ({
    key: file.key,
    duration: null,
    status: "prepare",
    error: null,
  }));
}

function extraStateReducer(state, action) {
  const { type, key, ...payload } = action;
  if (type === "remove-entry") {
    return state.filter((e) => e.key !== key);
  }
  const transform = getTransformationFunction(type);
  return state.map((e) => (e.key === key ? transform(e, payload) : e));
}

function getTransformationFunction(type) {
  if (type === "set-language") {
    return (entry, payload) => {
      const { language } = payload;
      return { ...entry, language };
    };
  }
  if (type === "set-resolution") {
    return (entry, payload) => {
      const { resolution } = payload;
      return { ...entry, resolution };
    };
  }
  if (type === "set-network") {
    return (entry, payload) => {
      const { network } = payload;
      return { ...entry, network };
    };
  }
  if (type === "set-cta") {
    return (entry, payload) => {
      const { cta } = payload;
      return { ...entry, cta };
    };
  }
  if (type === "set-status") {
    return (entry, payload) => ({
      ...entry,
      status: payload.status,
      error: payload.error || null,
    });
  }
  if (type === "set-change") {
    return (entry, payload) => {
      const { change } = payload;
      return { ...entry, change };
    };
  }
  return (entry) => entry;
}

function processUploadError(error, creativeId) {
  if (error.response && error.response.status === 413) {
    internalAxios.post("action-logs/", {
      custom_metadata: {
        messages: [{ text: "File size too large", type: "error" }],
      },
      creative_concept: creativeId,
      action_type: "end_card_upload",
    });
  }
}

export { UploadAssetsModal };
