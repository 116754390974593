import React from "react";
import { Button, Table } from "react-bootstrap";
import { Link, useLoaderData, defer } from "react-router-dom";

import {
  filtersLoader,
  PageFiltersForm,
} from "~/blocks/creatives/concepts/filters";
import { VideoFilePreview } from "~/blocks/file-preview/file-preview";
import { GdriveLink, ExternalLink } from "~/components/external-link";
import PageHeader from "~/components/page-header";
import Pagination from "~/components/pagination";
import SimpleAwaiter from "~/components/simple-awaiter";
import { isZephyrEnv } from "~/constants";
import { axios, extractSearch } from "~/utils";

function CreativeConceptsPage() {
  const { creatives, filtersPromise } = useLoaderData();
  const isZephyr = isZephyrEnv();
  const Page = isZephyr ? ZephyrPage : TDSPage;
  return <Page creatives={creatives} filtersPromise={filtersPromise} />;
}

function TDSPage({ creatives, filtersPromise }) {
  return (
    <>
      <PageHeader
        filter={
          <SimpleAwaiter promise={filtersPromise}>
            {(filters) => (
              <PageFiltersForm
                options={filters}
                filterNames={[
                  "application",
                  "creative_number",
                  "intro",
                  "identifier",
                  "agency",
                  "concept_name",
                  "creative_type",
                ]}
              />
            )}
          </SimpleAwaiter>
        }
        filterAlwaysOpened
      >
        <Button
          variant="primary"
          href="/creatives/concepts/agency-new"
          className="mx-3"
        >
          Link New Agency Creative
        </Button>
        <Button variant="primary" href="/creatives/concepts/new">
          Add New Creative
        </Button>
      </PageHeader>
      <CreativeConceptsTable data={creatives} />
    </>
  );
}

function ZephyrPage({ creatives, filtersPromise }) {
  return (
    <>
      <PageHeader
        filter={
          <SimpleAwaiter promise={filtersPromise}>
            {(filters) => (
              <PageFiltersForm
                options={filters}
                filterNames={[
                  "application",
                  "creative_number",
                  "concept_name",
                  "graphics_type",
                  "gameplay_type",
                ]}
              />
            )}
          </SimpleAwaiter>
        }
        filterAlwaysOpened
      >
        <Button variant="primary" href="/creatives/concepts/new">
          Add New Creative
        </Button>
      </PageHeader>
      <ZephyrCreativeConceptsTable data={creatives} />
    </>
  );
}

CreativeConceptsPage.loader = async function loader({ request }) {
  const search = extractSearch(request.url);
  const response = await axios(`creative-concepts${search}`);
  const filtersPromise = filtersLoader();

  return defer({
    creatives: response.data,
    filtersPromise,
  });
};

function CreativeConceptsTable({ data }) {
  return (
    <>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th scope="col" style={{ width: "4em" }}>
              ID
            </th>
            <th scope="col" style={{ width: "6em" }}>
              Preview
            </th>
            <th scope="col">App</th>
            <th scope="col">Creative #</th>
            <th scope="col">Intro</th>
            <th scope="col">Concept Name</th>
            <th scope="col">Creative Type</th>
            <th scope="col">Identifier</th>
            <th scope="col">Agency</th>
            <th scope="col">Asana Ticket</th>
            <th scope="col">Creative Folder Link</th>
            <th scope="col">View</th>
          </tr>
        </thead>
        <tbody>
          {data.results.map((concept) => (
            <tr key={concept.id}>
              <td>{concept.id}</td>
              <td>
                <VideoFilePreview
                  preview={concept.preview}
                  thumbnailWidth="70px"
                  thumbnailHeight="125px"
                />
              </td>
              <td>{concept.application_code}</td>
              <td>{concept.creative_number}</td>
              <td>{concept.intro}</td>
              <td>{concept.concept_name}</td>
              <td>{concept.creative_type_name}</td>
              <td>{concept.identifier}</td>
              <td>{concept.agency}</td>
              <td>
                <ExternalLink link={concept.asana_link} text="Link" />
              </td>
              <td>
                <GdriveLink link={concept.creative_folder_link} />
              </td>
              <td>
                <Link to={`/creatives/concepts/${concept.id}/assets`}>
                  <Button variant="outline-primary" className="mb-1 me-3">
                    View
                  </Button>
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination data={data} />
    </>
  );
}

function ZephyrCreativeConceptsTable({ data }) {
  return (
    <>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th scope="col" style={{ width: "4em" }}>
              ID
            </th>
            <th scope="col" style={{ width: "6em" }}>
              Preview
            </th>
            <th scope="col">App</th>
            <th scope="col">Creative #</th>
            <th scope="col">Creative Name</th>
            <th scope="col">2D / 3D</th>
            <th scope="col">Type</th>
            <th scope="col">Asana Ticket</th>
            <th scope="col">Creative Folder Link</th>
            <th scope="col">Source Folder Link</th>
            <th scope="col">View</th>
          </tr>
        </thead>
        <tbody>
          {data.results.map((concept) => (
            <tr key={concept.id}>
              <td>{concept.id}</td>
              <td>
                <VideoFilePreview
                  preview={concept.preview}
                  thumbnailWidth="70px"
                  thumbnailHeight="125px"
                />
              </td>
              <td>{concept.application_code}</td>
              <td>{concept.creative_number}</td>
              <td>{concept.concept_name}</td>
              <td>{concept.graphics_type}</td>
              <td>{concept.gameplay_type}</td>
              <td>
                <ExternalLink link={concept.asana_link} text="Link" />
              </td>
              <td>
                <GdriveLink link={concept.creative_folder_link} />
              </td>
              <td>
                <GdriveLink link={concept.source_folder_link} />
              </td>
              <td>
                <Link to={`/creatives/concepts/${concept.id}/assets`}>
                  <Button variant="outline-primary" className="mb-1 me-3">
                    View
                  </Button>
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination data={data} />
    </>
  );
}

export default CreativeConceptsPage;
