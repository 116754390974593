import moment from "moment";
import React from "react";
import { Button, Table } from "react-bootstrap";
import { useLoaderData, defer, useRevalidator } from "react-router-dom";

import { useAlert } from "~/base/alerts";
import PageHeader from "~/components/page-header";
import Pagination from "~/components/pagination";
import RetryButton from "~/components/retry-button";
import SimpleAwaiter from "~/components/simple-awaiter";
import { axios, extractSearch } from "~/utils";
import { useInterval } from "~/utils/time";

import { PageFiltersForm, filtersLoader } from "./filters";

function NetworkLaunchesPage() {
  const { launches, filtersPromise } = useLoaderData();
  const revalidator = useRevalidator();

  const refreshPage = () => {
    revalidator.revalidate();
  };
  useInterval(refreshPage, 10000);

  return (
    <>
      <PageHeader
        filter={
          <SimpleAwaiter promise={filtersPromise}>
            {(filters) => <PageFiltersForm options={filters} />}
          </SimpleAwaiter>
        }
        filterAlwaysOpened
      >
        <Button variant="primary" href="/network-launches/new">
          New Launch
        </Button>
      </PageHeader>
      <NetworkLaunchesTable data={launches} />
    </>
  );
}

NetworkLaunchesPage.loader = async function loader({ request }) {
  const search = extractSearch(request.url);
  const response = await axios(`network-launches${search}`);
  const filtersPromise = filtersLoader();

  return defer({
    launches: response.data,
    filtersPromise,
  });
};

function CampaignInfoRow({ name, value }) {
  return (
    <div>
      <b>{name}</b>: {value}
    </div>
  );
}

function ApplovinCampaignInfo({ launch }) {
  const adsComponents = launch.info.ads.map((ad, idx) => (
    <p key={ad.id}>
      {idx + 1}. {ad.name} - ID {ad.network_id} (
      {ad.enabled ? "Enabled" : "Disabled"})
    </p>
  ));
  return (
    <div>
      <a href={launch.info.campaign_link} target="_blank" rel="noreferrer">
        Campaign link
      </a>
      <CampaignInfoRow name="Language" value={launch.language} />
      <CampaignInfoRow name="Campaign name" value={launch.campaign_name} />
      <CampaignInfoRow name="Ads" value={adsComponents} />
      {launch.exception_message && (
        <CampaignInfoRow name="Exception" value={launch.exception_message} />
      )}
    </div>
  );
}

function UnityCampaignInfo({ launch }) {
  return (
    <div>
      <a href={launch.info.campaign_link} target="_blank" rel="noreferrer">
        Creative packs
      </a>
      <CampaignInfoRow name="Language" value={launch.language} />
      <CampaignInfoRow name="Platform" value={launch.info.platform} />
      {launch.exception_message && (
        <CampaignInfoRow name="Exception" value={launch.exception_message} />
      )}
    </div>
  );
}

function CreativeAssets({ launch }) {
  const objects = [
    { name: "End Card", data: launch.end_card },
    { name: "End Card 2", data: launch.end_card_2 },
    { name: "Playable", data: launch.playable },
    { name: "Landscape Video Asset", data: launch.landscape_video_asset },
    { name: "Portrait Video Asset", data: launch.portrait_video_asset },
  ];

  return (
    <div>
      {objects.map(
        (obj) =>
          obj.data && (
            <div key={obj.name}>
              <b>{obj.name}</b>: {obj.data.long_name}
            </div>
          )
      )}
    </div>
  );
}

function SyncApplovinAdsButton({ launch }) {
  const addAlert = useAlert();
  const syncAds = () =>
    axios({
      method: "POST",
      url: `applovin-launches/${launch.id}/sync/`,
    })
      .then(() => {
        addAlert("Successfully synced Applovin ads", "info");
      })
      .catch(() => {
        addAlert("Failed to sync Applovin ads", "error");
      });
  return (
    <Button variant="info" size="sm" onClick={syncAds}>
      Sync Ads
    </Button>
  );
}

function NetworkLaunchesTable({ data }) {
  function checkNetwork(launch, networkName) {
    return launch.network.toLowerCase() === networkName;
  }
  return (
    <>
      <Table className="network-launches-table" striped bordered hover>
        <thead>
          <tr>
            <th scope="col" className="short-cell">
              ID
            </th>
            <th scope="col">User</th>
            <th scope="col">Time</th>
            <th scope="col">Network</th>
            <th scope="col" className="short-cell">
              App
            </th>
            <th scope="col" className="long-cell">
              Creative Assets
            </th>
            <th className="long-cell" scope="col">
              Info
            </th>
            <th scope="col">Status</th>
            <th scope="col" className="short-cell">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {data.results.map((launch) => (
            <tr key={launch.id}>
              <td className="short-cell">{launch.id}</td>
              <td>{launch.creator}</td>
              <td>
                {moment.utc(launch.created_at).format("MMMM D, YYYY HH:mm:ss")}
              </td>
              <td>{launch.network}</td>
              <td className="short-cell">{launch.application}</td>
              <td className="long-cell">
                <CreativeAssets launch={launch} />
              </td>
              <td className="long-cell">
                {checkNetwork(launch, "applovin") && (
                  <ApplovinCampaignInfo launch={launch} />
                )}
                {checkNetwork(launch, "unity") && (
                  <UnityCampaignInfo launch={launch} />
                )}
              </td>
              <td>{launch.status}</td>
              <td className="short-cell">
                {launch.status.toLowerCase() !== "success" && (
                  <div className="mb-2">
                    <RetryButton
                      endpoint={
                        checkNetwork(launch, "unity")
                          ? `unity-launches/${launch.id}/retry/`
                          : `applovin-launches/${launch.id}/retry/`
                      }
                    />
                  </div>
                )}
                {checkNetwork(launch, "applovin") && (
                  <SyncApplovinAdsButton launch={launch} />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination data={data} />
    </>
  );
}

export default NetworkLaunchesPage;
