import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { useAlert } from "~/base/alerts";
import Form from "~/components/form";
import SubmitButton from "~/components/form/submit";
import { axios, snakeToCamel } from "~/utils";

import { AssetInputs, CreativePackType, LaunchFormRow } from "./utils";

import "./index.scss";

function formRequestData(values) {
  return {
    application: values.application,
    platform: values.platform,
    portrait_video_asset: values.portraitVideoAsset,
    landscape_video_asset: values.landscapeVideoAsset,
    end_card: values.endCard,
    end_card_2: values.endCard2,
    playable: values.playable,
  };
}

function UnityLaunchNetworkForm({ options, isZephyr }) {
  const navigate = useNavigate();
  const addAlert = useAlert();

  const onSubmit = (values, { setFieldError }) => {
    if (values.creativePackType !== CreativePackType.PLAYABLE) {
      if (!values.landscapeVideoAsset && !values.portraitVideoAsset) {
        setFieldError(
          "landscapeVideoAsset",
          "Please specify either Landscape or Portrait video asset."
        );
        setFieldError(
          "portraitVideoAsset",
          "Please specify either Landscape or Portrait video asset."
        );
        return;
      }
    }
    axios({
      method: "POST",
      url: "unity-launches/",
      data: formRequestData(values),
    })
      .then(() => {
        navigate("/network-launches");
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          const backendErrors = error.response.data;
          if (backendErrors.non_field_errors) {
            addAlert(backendErrors.non_field_errors[0], "error");
          } else {
            Object.keys(backendErrors).forEach((field) => {
              setFieldError(snakeToCamel(field), backendErrors[field]);
            });
          }
        } else {
          addAlert(
            "Unexpected error happened on form submission. Please try again or reach out to the MAD Products Team",
            "error"
          );
        }
      });
  };

  const validationSchema = Yup.object().shape({
    application: Yup.string().required("Please specify Application"),
    creativePackType: Yup.string().required(
      "Please specify Creative Pack Type"
    ),
    platform: Yup.string().required("Please specify Platform"),
    landscapeVideoAsset: Yup.number().nullable().notRequired(),
    portraitVideoAsset: Yup.number().nullable().notRequired(),
    endCard: Yup.number().when(["creativePackType"], {
      is: CreativePackType.UNITY_VIDEO_ENDCARD,
      then: Yup.number().required("Please specify End card"),
      otherwise: Yup.number().nullable().notRequired(),
    }),
    endCard2: Yup.number().nullable().notRequired(),
    playable: Yup.number().when(["creativePackType"], {
      is: CreativePackType.UNITY_VIDEO_ENDCARD,
      then: Yup.number().nullable().notRequired(),
      otherwise: Yup.number().required("Please specify Playable"),
    }),
  });
  const platforms = [
    { label: "ios", value: "ios" },
    { label: "android", value: "android" },
  ];
  const creativePackTypes = [
    { label: "Video & Playable", value: CreativePackType.UNITY_VIDEO_PLAYABLE },
    { label: "Playable", value: CreativePackType.PLAYABLE },
    {
      label: "Video & End Card",
      value: CreativePackType.UNITY_VIDEO_ENDCARD,
    },
  ];

  return (
    <Form
      form={false}
      initialValues={{
        application: null,
        portraitVideoAsset: null,
        landscapeVideoAsset: null,
        creativePackType: null,
        endCard: null,
        endCard2: null,
        playable: null,
        platform: null,
      }}
      validationSchema={validationSchema}
      syncSubmit
      onSubmit={onSubmit}
    >
      <LaunchFormRow label="Application:*">
        <Form.Select name="application" options={options.applications} />
        <Form.Control.ErrorFeedback name="application" />
      </LaunchFormRow>
      <LaunchFormRow label="Platform:*">
        <Form.Select name="platform" options={platforms} />
        <Form.Control.ErrorFeedback name="platform" />
      </LaunchFormRow>
      <LaunchFormRow label="Creative pack type:*">
        <Form.Select name="creativePackType" options={creativePackTypes} />
        <Form.Control.ErrorFeedback name="creativePackType" />
      </LaunchFormRow>
      <AssetInputs
        isZephyr={isZephyr}
        optionsEndpoint="unity-launches/asset-options"
      />
      <Row>
        <Col xs="auto">
          <SubmitButton className="px-4" icon={false} title="Launch" />
        </Col>
      </Row>
    </Form>
  );
}

export default UnityLaunchNetworkForm;
