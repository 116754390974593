import moment from "moment";
import React from "react";
import { Card, Col, Row, Table } from "react-bootstrap";
import { useLoaderData, defer, useRevalidator } from "react-router-dom";

import { filtersLoader } from "~/blocks/creatives/assets/filters";
import {
  DeleteFilesButton,
  SelectAllFilesCheckbox,
} from "~/blocks/delete-files";
import { PreviewableText } from "~/blocks/file-preview/file-preview";
import { GdriveLink, ExternalLink } from "~/components/external-link";
import PageHeader from "~/components/page-header";
import Pagination from "~/components/pagination";
import { isZephyrEnv } from "~/constants";
import { axios, extractSearch, addDefaultOrderingToSearch } from "~/utils";

import { AssetDropzone, GdriveCredsDropzoneGuard } from "./dropzone";
import { UploadAssetsModal } from "./upload-modal";
import { ZephyrUploadAssetsModal } from "./zephyr-upload-modal";

import "./assets.scss";

function PlayableAssetsPage() {
  return <PlayableAssetsFunctionPage />;
}

function PlayableAssetsFunctionPage() {
  const { playableAssets, playable, languages, networks } = useLoaderData();

  const revalidator = useRevalidator();

  const refreshPage = () => {
    revalidator.revalidate();
  };
  const UploadModal = isZephyrEnv()
    ? ZephyrUploadAssetsModal
    : UploadAssetsModal;
  return (
    <>
      <PageHeader />
      <Row className="mb-4 pt-4">
        <Col xs={6}>
          <PlayableConceptDescriptionBlock playable={playable} />
        </Col>
        <Col xs={6}>
          <GdriveCredsDropzoneGuard>
            <AssetDropzone>
              {({ filesToUpload, removeFile, clearFiles }) =>
                !!filesToUpload.length && (
                  <UploadModal
                    filesToUpload={filesToUpload}
                    onFileRemove={removeFile}
                    onClose={(shouldRefreshPage) => {
                      clearFiles();
                      if (shouldRefreshPage) {
                        refreshPage();
                      }
                    }}
                    playable={playable}
                    languages={languages}
                    networks={networks}
                  />
                )
              }
            </AssetDropzone>
          </GdriveCredsDropzoneGuard>
        </Col>
      </Row>
      <PlayableAssetsTable data={playableAssets} refreshPage={refreshPage} />
    </>
  );
}

PlayableAssetsPage.loader = async function loader({ params, request }) {
  const search = extractSearch(request.url);
  const defaultOrderingTemplate =
    "ordering=normalized_change,version,language_field,network_field";
  const updatedSearch = addDefaultOrderingToSearch(
    search,
    defaultOrderingTemplate
  );
  const [assetsResponse] = await Promise.all([
    axios(`playables/concept/${params.conceptID}${updatedSearch}`),
  ]);
  const [playableResponse] = await Promise.all([
    axios(`playable-concepts/${params.conceptID}`),
  ]);
  const [languagesResponse] = await Promise.all([axios("languages/")]);
  const [networksResponse] = await Promise.all([axios("networks/")]);
  const filtersPromise = filtersLoader();

  return defer({
    playableAssets: assetsResponse.data,
    playable: playableResponse.data,
    languages: languagesResponse.data,
    networks: networksResponse.data,
    filtersPromise,
  });
};

function PlayableAssetsTable({ data, refreshPage }) {
  const [selectedPlayables, setSelectedPlayables] = React.useState([]);
  const toggleRowSelection = (id) => {
    if (selectedPlayables.includes(id)) {
      setSelectedPlayables(selectedPlayables.filter((rowId) => rowId !== id));
    } else {
      setSelectedPlayables([...selectedPlayables, id]);
    }
  };
  const PlayablesTableComponent = isZephyrEnv()
    ? ZephyrPlayablesTable
    : PlayablesTable;
  return (
    <>
      <DeleteFilesButton
        endpoint="playables"
        files={selectedPlayables}
        refreshPage={refreshPage}
      />
      <PlayablesTableComponent
        selectedPlayables={selectedPlayables}
        setSelectedPlayables={setSelectedPlayables}
        data={data}
        toggleRowSelection={toggleRowSelection}
      />
      <Pagination data={data} />
    </>
  );
}

function PlayablesTable({
  selectedPlayables,
  setSelectedPlayables,
  data,
  toggleRowSelection,
}) {
  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th scope="col">
            <SelectAllFilesCheckbox
              selectedFiles={selectedPlayables}
              setSelectedFiles={setSelectedPlayables}
              allFiles={data.results}
            />
          </th>
          <th scope="col" style={{ width: "4em" }}>
            ID
          </th>
          <th scope="col">Name</th>
          <th scope="col">Date</th>
          <th scope="col">Network</th>
          <th scope="col">CTA</th>
          <th scope="col">Number of clicks</th>
          <th scope="col">Change</th>
          <th scope="col">Language</th>
          <th scope="col">Google Drive Link</th>
        </tr>
      </thead>
      <tbody>
        {data.results.map((playable) => (
          <tr
            key={playable.id}
            style={{ height: "4em", verticalAlign: "middle" }}
          >
            <td>
              <input
                type="checkbox"
                checked={selectedPlayables.includes(playable.id)}
                onChange={() => toggleRowSelection(playable.id)}
              />
            </td>
            <td>{playable.id}</td>
            <td>
              <PreviewableText
                preview={playable.preview}
                text={playable.long_name}
              />
            </td>
            <td>
              {moment.utc(playable.created_at).format("DD.MM.YYYY, HH:mm:ss")}
            </td>
            <td>{playable.network_field}</td>
            <td>{playable.cta_field}</td>
            <td>{playable.number_of_clicks}</td>
            <td>{playable.change}</td>
            <td>{playable.language_field}</td>
            <td>
              <GdriveLink link={playable.creative_link} />
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

function ZephyrPlayablesTable({
  selectedPlayables,
  setSelectedPlayables,
  data,
  toggleRowSelection,
}) {
  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th scope="col">
            <SelectAllFilesCheckbox
              selectedFiles={selectedPlayables}
              setSelectedFiles={setSelectedPlayables}
              allFiles={data.results}
            />
          </th>
          <th scope="col" style={{ width: "4em" }}>
            ID
          </th>
          <th scope="col">Date</th>
          <th scope="col">Uploaded by</th>
          <th scope="col">Name</th>
          <th scope="col">Version</th>
          <th scope="col">Number of clicks</th>
          <th scope="col">Network</th>
          <th scope="col">Language</th>
          <th scope="col">Google Drive Link</th>
        </tr>
      </thead>
      <tbody>
        {data.results.map((playable) => (
          <tr
            key={playable.id}
            style={{ height: "4em", verticalAlign: "middle" }}
          >
            <td>
              <input
                type="checkbox"
                checked={selectedPlayables.includes(playable.id)}
                onChange={() => toggleRowSelection(playable.id)}
              />
            </td>
            <td>{playable.id}</td>
            <td>
              {moment.utc(playable.created_at).format("DD.MM.YYYY, HH:mm:ss")}
            </td>
            <td>{playable.author_name}</td>
            <td>
              <PreviewableText
                preview={playable.preview}
                text={playable.long_name}
              />
            </td>
            <td>{playable.version}</td>
            <td>{playable.number_of_clicks}</td>
            <td>{playable.network_field}</td>
            <td>{playable.language_field}</td>
            <td>
              <GdriveLink link={playable.creative_link} />
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

function PlayableConceptDescriptionBlock({ playable }) {
  return (
    <Card>
      <Card.Body>
        <Row className="mb-3">
          <Row>
            <Col style={{ fontWeight: "bold" }}>Date</Col>
            <Col style={{ fontWeight: "bold" }}>App</Col>
            <Col style={{ fontWeight: "bold" }}>Playable #</Col>
            <Col style={{ fontWeight: "bold" }}>Playable Theme</Col>
          </Row>
          <Row>
            <Col>
              {moment.utc(playable.created_at).format("DD.MM.YYYY, HH:mm:ss")}
            </Col>
            <Col>{playable.application_code}</Col>
            <Col>{playable.creative_number}</Col>
            <Col>{playable.theme}</Col>
          </Row>
        </Row>
        <Row>
          <Row>
            <Col style={{ fontWeight: "bold" }}>User</Col>
            <Col style={{ fontWeight: "bold" }}>Playable Folder Link</Col>
            <Col style={{ fontWeight: "bold" }}>Asana Ticket</Col>
            <Col />
          </Row>
          <Row>
            <Col>{playable.created_by_name}</Col>
            <Col>
              <GdriveLink link={playable.creative_folder_link} />
            </Col>
            <Col>
              <ExternalLink link={playable.asana_link} text="Link" />
            </Col>
            <Col />
          </Row>
        </Row>
      </Card.Body>
    </Card>
  );
}

export default PlayableAssetsPage;
